import React, { useContext, useEffect, useState } from "react";
import { BaseStoreContext } from "../store";
import { observer } from "mobx-react-lite";
import { Player } from '@lottiefiles/react-lottie-player';
import logo from '../assets/images/register.json';
import { MainButton, useShowPopup } from '@vkruglikov/react-telegram-web-app';
import { Input } from 'antd';
import Page from '../components/Page';

const AuthPage = () => {
  const store = useContext(BaseStoreContext);
  const showPopup = useShowPopup();
  const [goldenKey, setGoldenKey] = useState<string>('');
  const click = () => {
    if(goldenKey.length > 5) {
      showPopup({
        title: 'Предупреждение',
        message: "Я согласен с лицензионным соглашением площадки funpay.com, и не собираюсь нарушать их при помощи менеджера.",
        buttons: [
          {
            id: 'ok',
            type: 'ok',
            text: "Нет"
          },
          {
            id: 'no',
            type: 'destructive',
            text: 'Нет',
          },
        ],
      }).then(e => {
        if(e === 'ok') {
          store.registerAccount(goldenKey);
        }
      });
    }
  }

  return <Page Name='register'>
    <header className="App-header">
      <Player
        autoplay
        loop
        controls={false}
        src={logo}
        style={{ height: '150px', width: '150px' }}
      >
      </Player>
    </header>
    <div className="contentWrapper" style={{textAlign: 'center'}}>
      <p>Чтобы мне приступить к работе мне требуется токен с сайта funpay</p>
      <p>Для этого следуйте инструкциям</p>
      <ul style={{textAlign: 'left'}}>
        <li>Зайдите в Chrome, откройте funpay.com и авторизуйтесь</li>
        <li>Нажмите F12</li>
        <li>Зайдите в Application</li>
        <li>Выберите Cookies {'->'} Funpay.com</li>
        <li>Найдите golden_key и скопируйте его содержимое</li>
        <li>Вставьте в поле</li>
      </ul>
      <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ' target='_blank' rel='noreferrer'>Видео инструкция</a>
    </div>
    <div className="contentWrapper" style={{textAlign: 'left'}}>
      <div>
        <Input onChange={e => setGoldenKey(e.target.value)} />
      </div>
    </div>
    <div className='text' style={{ textAlign: 'center', opacity: '0.15', fontSize: '10pt' }}>
      Мы как команда разработчиков настоятельно рекомендуем создать новый аккаунт и продвигать его.
      Если к Вашей учетной записи был доступ через других ботов или софт мы не можем вам гарантировать безопасность из-за структуры работы самого FunPay.
      Мы с своей стороны шифруем ваши данные и делаем максимально возможную защиту для их сохранности, но не можем её гарантировать если кто-то еще имеет доступ к учетной записи.
      Запомните - Никогда и никому не передавайте свой токен от аккаунта, не показывайте экран при использовании токена, и не делайте скриншотов токена.
    </div>
    {goldenKey.length > 5 && <MainButton text="Добавить менеджера" onClick={click} /> }
  </Page>;
};

export default observer(AuthPage);