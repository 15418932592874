import React, { useContext, useEffect, useState } from "react";
import { BaseStoreContext } from "../store";
import { observer } from "mobx-react-lite";
import { Player } from '@lottiefiles/react-lottie-player';
import logo from '../assets/images/welcome.json';
import { MainButton } from '@vkruglikov/react-telegram-web-app';
import Page from '../components/Page';

const AuthPage = () => {
  const store = useContext(BaseStoreContext);

  return <Page Name='auth'>
    <header className="App-header">
      <Player
        autoplay
        loop
        controls={false}
        src={logo}
        style={{ height: '250px', width: '250px' }}
      >
      </Player>
    </header>
    <div className="contentWrapper" style={{textAlign: 'center'}}>
      <div>Привет <b>{store.user?.first_name}</b></div>
      <div>Я активен и готов работать 🫡</div>
    </div>
    <div>

    </div>
    <MainButton text='Приступить' onClick={() => store.Go('register')} />
  </Page>;
};

export default observer(AuthPage);