import React, { FC, useContext, useEffect, useState } from 'react';
import { BaseStoreContext } from "../store";
import { observer } from "mobx-react-lite";
import { Player } from '@lottiefiles/react-lottie-player';
import logo from '../assets/images/welcome.json';
import { BackButton, MainButton } from '@vkruglikov/react-telegram-web-app';
import { motion } from "framer-motion"

type Props = {
  Name: string;
  children?: any;
  EnabledBackButton?: boolean | null;
  ignoreHistory?: boolean;
};


const Page: FC<Props> = ({
                           Name,
                           children,
                           EnabledBackButton = true,
                           ignoreHistory=false
}) => {
  const store = useContext(BaseStoreContext);
  let options: any;
  console.log(0);
  if (store.tempPage != null && store.tempPage[0] === Name) {
    console.log(1);
    store.currentPage = Name;
    options = store.tempPage[1];
    if (!ignoreHistory) {
      store.history.push(store.tempPage);
      console.log(store.history);
    }
    store.tempPage = null;
  }
  if (store.currentPage !== Name)
    return null;
  const initial = {
    x: 0,
    opacity: 1
  };
  const animate = {
    x: -500,
    opacity: 0
  };
  return <motion.div
    initial={initial}
    animate={{}}
    className={`page page-${Name}`}
  >
    {children}
    {EnabledBackButton && <BackButton
      onClick={() => {
        const prev = store.history.slice(-1);
        console.log(prev);
        // store.Go();
      }}
    />}
  </motion.div>;
  return <motion.div
    className="box"
    animate={{
      scale: [1, 2, 2, 1, 1],
      rotate: [0, 0, 180, 180, 0],
      borderRadius: ["0%", "0%", "50%", "50%", "0%"]
    }}
    transition={{
      duration: 2,
      ease: "easeInOut",
      times: [0, 0.2, 0.5, 0.8, 1],
      repeat: Infinity,
      repeatDelay: 1
    }}
  />;
};

export default observer(Page);