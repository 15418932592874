import React, { useContext, useEffect, useState } from "react";
import { BaseStoreContext } from "../../store";
import { observer } from "mobx-react-lite";
import Page from '../../components/Page';

const DeliveryPage = () => {
  const store = useContext(BaseStoreContext);

  return <Page Name="delivery">
    delivery
  </Page>;
};

export default observer(DeliveryPage);