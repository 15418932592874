import React, { useContext, useEffect, useState } from "react";
import { BaseStoreContext } from "../../store";
import { observer } from "mobx-react-lite";
import Page from '../../components/Page';

const WithdrawPage = () => {
  const store = useContext(BaseStoreContext);

  return <Page Name="withdraw">
    <div>
      <select name="currency">
        <option value="USD">Доллары США (USD)</option>
        <option value="RUB">Российские рубли (RUB)</option>
        <option value="EUR">Евро (EUR)</option>
      </select>
    </div>
    <div>
      <select name="wallet">
        <option value="QIWI">Qiwi кошелек</option>
        <option value="BINANCE">Binance Pay</option>
        <option value="TRC">USDT TRC20</option>
        <option value="CARD_RUB">Рублевая банковская карта</option>
        <option value="CARD_USD">Долларовая банковская карта</option>
        <option value="CARD_EUR">Евро банковская карта</option>
        <option value="WEBMONEY">WebMoney WMZ</option>
        <option value="YOUMONEY">ЮMoney</option>
      </select>
    </div>
    <div>
      <input name="amount" type="number" value="1.0" placeholder="Сумма" />
    </div>
    <div>
      <input name="address" type="text" placeholder="Кошелек" />
    </div>
    <div>
      <button>Вывести сейчас</button>
    </div>
    <div>
      <input name="amount" type="number" placeholder="Минимальная сумма для авто вывода" />
    </div>
    <div>
      <button>Включить/Выключить авто вывод</button>
    </div>
  </Page>;
};

export default observer(WithdrawPage);