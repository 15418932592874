import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import axios from 'axios';
import { WebApp } from '@vkruglikov/react-telegram-web-app/lib/core';

interface WebAppUser {
  id: number;
  is_bot?: boolean;
  first_name: string;
  last_name?: string;
  username?: string;
  language_code?: string;
  photo_url?: true;
}

class BaseStore {
  apiBaseUrl = process.env.REACT_APP_API_URL;
  currentPage = 'loading';
  tempPage: [string, any] | null = ['loading', null];
  history: [any?] = [];
  app: WebApp  | undefined = undefined;
  user: WebAppUser | undefined = undefined;
  token: string | undefined = undefined;
  accounts_count = 0;
  accounts: Map<string, any> = new Map<string, any>();
  selected_account: string | null = null;

  getHeader() {
    return {'authorization': 'Bearer ' + this.token};
  }

  getApi(path: string, query=false) : string {
    if(query && this.app) {
      return this.apiBaseUrl + path + '?' + this.app.initData;
    }
    return this.apiBaseUrl + path;
  }

  init(app: WebApp) {
    this.app = app;
    this.user = app.initDataUnsafe.user;
    this.setPage('loading');
    setTimeout(() => {
      axios({
        method: 'post',
        url: this.getApi('/auth', true)
      }).then(({ data }) => {
        const { token, accounts } = data;
        this.token = token;
        this.accounts_count = accounts;
        if(accounts > 0) {
          axios({
            method: 'get',
            url: this.getApi('/auth/accounts'),
            headers: this.getHeader(),
          }).then(({ data }) => {
            const { result } = data;
            for(const obj of result) {
              this.accounts.set(obj['id'], obj);
            }
          });
          this.setPage('accounts');
        } else {
          this.setPage('auth');
        }
      });
    }, 2000)
  }

  registerAccount(golden_key: string) {
    this.setPage('loading');
    axios({
      method: 'post',
      url: this.getApi('/auth/register'),
      headers: this.getHeader(),
      data: {
        golden_key: golden_key
      }
    }).then(({ data }) => {
      const { created_id } = data;
      axios({
        method: 'get',
        url: this.getApi('/auth/accounts'),
        headers: this.getHeader(),
      }).then(({ data }) => {
        const { result } = data;
        for(const obj of result) {
          this.accounts.set(obj['id'], obj);
          axios({
            method: 'get',
            url: this.getApi(`/dashboard/${created_id}`),
            headers: this.getHeader(),
            data: {
              golden_key: golden_key
            }
          }).then(({ data }) => {
            this.setPage('dashboard');
            this.selected_account = created_id;
          });
        }
      });
    });
  }

  getAcc() {
    if(this.selected_account !== null)
      return this.accounts.get(this.selected_account);
  }

  openDashboard(account_id: string) {
    this.setPage('loading');
    axios({
      method: 'get',
      url: this.getApi(`/dashboard/${account_id}`),
      headers: this.getHeader(),
    }).then(({ data }) => {
      this.setPage('dashboard');
      this.selected_account = account_id;
    });
  }

  setPage(name: string) {
    this.currentPage = name;
  }

  Go(name: string, options: any = null) {
    this.tempPage = [name, options];
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export const baseStore = new BaseStore();
export const BaseStoreContext = createContext(baseStore);