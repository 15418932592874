import React, { useContext, useEffect, useState } from "react";
import { BaseStoreContext } from "../../store";
import { observer } from "mobx-react-lite";
import { Player } from '@lottiefiles/react-lottie-player';
import logo from '../../assets/images/loading.json';
import { MainButton } from '@vkruglikov/react-telegram-web-app';
import Page from '../../components/Page';

const AutoReplyPage = () => {
  const store = useContext(BaseStoreContext);

  return <Page Name="autoreply">
    auto reply
  </Page>;
};

export default observer(AutoReplyPage);