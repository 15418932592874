import React, { useContext, useEffect, useState } from "react";
import { BaseStoreContext } from "../store";
import { observer } from "mobx-react-lite";
import { Player } from '@lottiefiles/react-lottie-player';
import logo_accounts from '../assets/images/accounts.json';
import { MainButton } from '@vkruglikov/react-telegram-web-app';
import { Button } from 'antd';
import Page from '../components/Page';

const AuthPage = () => {
  const store = useContext(BaseStoreContext);
  const accounts: any[] = [];
  store.accounts.forEach((_, value, m) => {
    const obj = m.get(value);
    accounts.push(<div className='account' key={obj.id}>
      <Button key={value} onClick={() => store.openDashboard(value)}>
        <div>
          <div className="avatar">
            <img src={obj.profile_photo ? obj.profile_photo : 'https://funpay.com/img/layout/avatar.png'} alt="" />
          </div>
          <div className='user'>
            {obj.username ? obj.username : 'Аноним'}
          </div>
          {obj.balance !== 0 && <div className='balance'>{obj.balance}{obj.currency}</div>}
        </div>
      </Button>
    </div>);
  });
  return <Page Name='accounts' EnabledBackButton={false}>
    <header className="App-header">
      <Player
        autoplay
        loop
        controls={false}
        src={logo_accounts}
        style={{ height: '150px', width: '150px' }}
      >
      </Player>
      <h5>Добавленные аккаунты</h5>
    </header>
    <div className='contentWrapper'>
      <div className='accounts'>
        {accounts}
      </div>
    </div>
    <div className='link'>
      <a href='#' onClick={() => store.Go('register')}>или добавьте новый</a>
    </div>
  </Page>;
};

export default observer(AuthPage);