import React, { useContext, useEffect, useState } from "react";
import { BaseStoreContext } from "../store";
import { observer } from "mobx-react-lite";
import { Player } from '@lottiefiles/react-lottie-player';
import logo from '../assets/images/loading.json';
import Page from '../components/Page';

const LoadingPage = () => {
  return <Page Name='loading' EnabledBackButton={false} ignoreHistory={true}>
    <Player
      autoplay
      loop
      controls={false}
      src={logo}
      style={{ height: '250px', width: '250px' }}
    >
    </Player>
    <div className='text' style={{textAlign: 'center'}}>
      <b>Загрузка...</b>
    </div>
  </Page>;
};

export default observer(LoadingPage);