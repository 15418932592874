import React, { useContext, useEffect, useState } from "react";
import { BaseStoreContext } from "../../store";
import { observer } from "mobx-react-lite";
import Page from '../../components/Page';

const ChatPage = () => {
  const store = useContext(BaseStoreContext);

  return <Page Name="lot">
    lot
  </Page>;
};

export default observer(ChatPage);