import React, { DispatchWithoutAction, FC, useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import {
  BackButton,
  useThemeParams,
  WebAppProvider,
} from '@vkruglikov/react-telegram-web-app';
import { ConfigProvider, theme } from 'antd';
import 'antd/dist/reset.css';
import './index.css';

import { Provider, observer } from "mobx-react";
import { baseStore, BaseStoreContext } from './store';
import PageAuth from './pages/auth';
import PageLoading from './pages/loading';
import PageRegister from './pages/register';
import PageAccounts from './pages/accounts';
import PageDashboard from './pages/dashboard/dashboard';
import PageAutoReply from './pages/dashboard/autoreply';
import PageChat from './pages/dashboard/chat';
import PageDelivery from './pages/dashboard/delivery';
import PageSettings from './pages/dashboard/settings';
import PageStatistics from './pages/dashboard/statistics';
import PageWithdraw from './pages/dashboard/withdraw';
import PageLot from './pages/dashboard/lot';
import PageRaiseLots from './pages/dashboard/raiselots';
import ComponentBackButton from './components/BackButton';

const DemoApp: FC<{onChangeTransition: DispatchWithoutAction;}> = observer(({ onChangeTransition }) => {
  const [colorScheme, themeParams] = useThemeParams();
  const store = useContext(BaseStoreContext);

  useEffect(() => {
    const app = (window as any).Telegram?.WebApp;
    if (app) {
      app.ready();
      store.init(app);
      // baseStore.app = app;
      // baseStore.user = app.initDataUnsafe.user;
    }
  }, [store]);

  return (
    <div>
      <ConfigProvider
        theme={
          themeParams.text_color
            ? {
                algorithm:
                  colorScheme === 'dark'
                    ? theme.darkAlgorithm
                    : theme.defaultAlgorithm,
                token: {
                  colorText: themeParams.text_color,
                  colorPrimary: themeParams.button_color,
                  colorBgBase: themeParams.bg_color,
                },
              }
            : undefined
        }
      >
        <PageLoading />
        <PageAuth />
        <PageRegister />
        <PageAccounts />
        <PageDashboard />

        <PageAutoReply />
        <PageChat />
        <PageDelivery />
        <PageLot />
        <PageSettings />
        <PageStatistics />
        <PageWithdraw />
        <PageRaiseLots />
        <ComponentBackButton />
      </ConfigProvider>
    </div>
  );
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const App = () => {
  const [smoothButtonsTransition, setSmoothButtonsTransition] = useState(false);

  return (
    <BaseStoreContext.Provider value={baseStore}>
      <WebAppProvider options={{ smoothButtonsTransition }}>
          <DemoApp
            onChangeTransition={() => setSmoothButtonsTransition(state => !state)}
          />
      </WebAppProvider>
    </BaseStoreContext.Provider>
  );
};

root.render(<App />);
