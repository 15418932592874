import React, { useContext, useEffect, useState } from "react";
import { BaseStoreContext } from "../../store";
import { observer } from "mobx-react-lite";
import Page from '../../components/Page';

const RaiseLotsPage = () => {
  const store = useContext(BaseStoreContext);

  return <Page Name="raiselots">
    <select>
      <option value={"478"}>8 Ball Pool</option>
    </select>
    <button>Включить/Отключить</button>
    <div>
      Подсвечивать только те категории, где есть товар
    </div>
    <div>
      Автоматически поднимать категории по окончанию ожидания
    </div>
    <div>
      таблица последних 10 логов успешных поднятий
    </div>
  </Page>;
};

export default observer(RaiseLotsPage);