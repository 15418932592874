import React, { useContext, useEffect, useState } from "react";
import { BaseStoreContext } from "../store";
import { observer } from "mobx-react-lite";
import { Player } from '@lottiefiles/react-lottie-player';
import logo from '../assets/images/welcome.json';
import { BackButton, MainButton } from '@vkruglikov/react-telegram-web-app';

const AuthPage = observer(() => {
  const store = useContext(BaseStoreContext);

  if(['autoreply', 'chat', 'delivery', 'lot', 'settings', 'statistics', 'withdraw', 'raiselots'].includes(store.currentPage)) {
    return <BackButton
      onClick={() => {
        store.Go('dashboard');
      }}
    />;
  }
  return null;
});

export default AuthPage;