import React, { useContext, useEffect, useState } from "react";
import { BaseStoreContext } from "../../store";
import { observer } from "mobx-react-lite";
import Page from '../../components/Page';
import { Player } from '@lottiefiles/react-lottie-player';
import logo_dashboard from '../../assets/images/dashboard.json';
import Icon, {IconType} from '@icon-park/react/es/all';

const AuthPage = () => {
  const store = useContext(BaseStoreContext);
  if(store.selected_account === null)
    return null;
  const account = store.getAcc();

  return <Page Name="dashboard">
    {/*<div>Subscribe info</div>*/}
    <Player
      autoplay
      loop
      controls={false}
      src={logo_dashboard}
      style={{ height: '150px', width: '150px' }}
    >
    </Player>
    <header className="App-header">
      <h5>Привет {account.username}</h5>
    </header>
    <div className="contentWrapper">
      <div className="text">
        У вас {account.active_sales}{account.active_purchases > 0 && ` (${account.active_purchases})`} продаж, сейчас на
        балансе {account.balance}{account.currency}.
      </div>
      <div className="actions" style={{ marginTop: '10px' }}>
        {/*<Aiming theme="outline" size="24" fill="#333"/>*/}
        <button onClick={() => store.Go('withdraw')}>
          <div className="icon">
            <Icon type="Bill" />
          </div>
          <div className="text">
            Вывод средств
          </div>
        </button>
        <button onClick={() => store.Go('delivery')}>
          <div className="icon">
            <Icon type="MoreApp" />
          </div>
          <div className="text">
            Авто выдача
          </div>
        </button>
        <button onClick={() => store.Go('raiselots')}>
          <div className="icon">
            <Icon type="ShareThree" />
          </div>
          <div className="text">
            Поднятие слотов
          </div>
        </button>
        <button onClick={() => store.Go('autoreply')}>
          <div className="icon">
            <Icon type="MessageEmoji" />
          </div>
          <div className="text">
            Авто ответ
          </div>
        </button>
        <button onClick={() => store.Go('statistics')}>
          <div className="icon">
            <Icon type="Analysis" />
          </div>
          <div className="text">
            Статистика
          </div>
        </button>
        <button onClick={() => store.Go('settings')}>
          <div className="icon">
            <Icon type="SettingConfig" />
          </div>
          <div className="text">
            Настройки
          </div>
        </button>
      </div>
    </div>
    {account.active_sales > 0 && <>
      <div style={{textAlign: 'center', marginTop: '10px'}}>
        <h5>Активные лоты</h5>
      </div>
      <div className="contentWrapper">
        <div></div>
      </div>
    </>}
  </Page>
;
};

export default observer(AuthPage);